// 判断设备
export function isAndroid () {
    const u = navigator.userAgent.toLowerCase()
    return u.indexOf('android') > -1 || u.indexOf('linux') > -1
}

// 判断微信
export function isWeixin() {
    let isWeixin = false
    const ua = navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        isWeixin = true
    }
    return isWeixin
}